footer {
  padding: 4rem 0;
  background-color: var(--color-medium);

  font: normal normal 300 1.5rem "Lato", sans-serif;
  color: var(--text-medium);
  line-height: 1.3em;
}

footer .footer-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  gap: 2rem;

  width: 75%;
  margin: auto;
}

footer .logo {
  width: 100%;
  max-width: 250px;
  height: auto;
}

footer ul {
  margin: 0;
  padding: 0;

  list-style: none;
}

footer span {
  display: block;
  margin-bottom: 1.25rem;

  font: normal normal 700 1.5rem "Poppins", sans-serif;
  color: var(--text-dark);
  text-transform: uppercase;
}

footer a {
  font: normal normal 700 1rem "Lato", sans-serif;
  color: var(--text-medium);
  text-decoration: none;

  transition: color 0.3s ease;
}

footer a:hover {
  color: var(--text-accent);
}

footer address {
  font-style: normal;
}

footer address a {
  display: block;

  font: normal normal 300 1.5rem "Lato", sans-serif;
  color: var(--text-accent);
  text-decoration: none;

  transition: color 0.3s ease;
}

footer address a:hover {
  color: var(--text-medium);
}

footer #footer-nav {
  grid-column-start: 1;
  grid-column-end: 5;

  margin-top: 1.75rem;
  justify-self: center;
}

footer #footer-nav ul li {
  display: inline-block;
}

footer #footer-nav ul li:not(:last-child) {
  margin-right: 3rem;
}

footer .social-link {
  width: 30px;
  height: auto;
  margin-right: 1rem;

  color: var(--text-accent);

  transition: color 0.3s ease;
}

footer .social-link:hover {
  color: var(--text-medium);
}

/*********************
* ===== Tablet ===== *
*********************/
@media (max-width: 1024px) {
  footer .footer-grid {
    grid-template-columns: repeat(3, 1fr);
    width: 90%;
  }

  footer .logo {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  footer #footer-nav {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

/*********************
* ===== Mobile ===== *
*********************/
@media (max-width: 650px) {
  footer .footer-grid {
    grid-template-columns: 1fr;

    text-align: center;
  }

  footer .footer-grid *,
  footer #footer-nav {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  footer .logo {
    max-width: 175px;
  }
}
