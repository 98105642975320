body.no-scroll {
  overflow: hidden;
}

header {
  position: fixed;
  top: 0;

  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;

  width: 100%;
  padding: 1rem 0;
  background-color: var(--color-light);
  border-bottom: 1px solid rgba(0, 0, 0, 10%);

  z-index: 4;
  box-sizing: border-box;
}

header.fixed-header {
  box-shadow: var(--header-shadow);
  -moz-box-shadow: var(--header-shadow);
  -webkit-box-shadow: var(--header-shadow);

  height: calc(30px + 2rem);
}

header #main-nav {
  width: 70%;
  margin: auto;
}

header .logo {
  height: 100%;
  width: auto;
}

header ul {
  margin: 0;
  padding: 0;

  list-style: none;
  text-align: right;
}

header ul li {
  display: inline-block;
  line-height: 50px;
  height: 50px;
  width: auto;

  transition: height 0.3s ease, line-height 0.3s ease;
}

header.fixed-header ul li {
  line-height: 30px;
  height: 30px;
}

header ul li:first-child {
  float: left;
}

header ul li:last-child {
  display: none;
}

header ul li:not(:last-child):not(:nth-child(6)) {
  margin-right: 2.5rem;
}

header ul li a {
  color: var(--text-medium);
  text-decoration: none;
  cursor: pointer;

  transition: opacity 0.3s ease;
}

header ul li:not(:first-child) a:hover {
  opacity: 0.7;
}

/* ===== Tablet ===== */
@media (max-width: 1024px) {
  header #main-nav {
    width: 86%;
  }
}

/* ===== Mobile ===== */
@media (max-width: 790px) {
  header ul li a:hover {
    opacity: 1;
  }

  header ul li:not(:first-child) {
    display: none;
  }

  header ul li:last-child {
    display: initial;
  }

  header ul li .menu-bars {
    width: 25px;
    height: auto;
    vertical-align: middle;

    color: var(--text-accent);
  }

  /* ----- Nav: Nav Items ----- */
  header ul li:not(:first-child):not(:last-child) a {
    display: block;
    width: 200px;
    padding: 15px 20px;

    box-sizing: border-box;
  }

  header ul li:not(:first-child):not(:last-child) {
    display: initial;
    position: absolute;
    right: 0;
    min-width: 0;
    width: 0;
    height: auto;
    box-sizing: border-box;

    padding: 0;
    margin-right: 0;
    overflow: hidden;

    background-color: var(--color-light);
    border-bottom: 1px solid rgba(0, 0, 0, 10%);
    text-align: left;

    z-index: 3;

    transition: min-width 0.3s ease, padding 0.3s ease;
  }

  header.mobile-nav-open ul li:not(:first-child):not(:last-child) {
    min-width: 200px;
  }

  header ul li:nth-child(2),
  header ul li:nth-child(3),
  header ul li:nth-child(4),
  header ul li:nth-child(5),
  header ul li:nth-child(6) {
    line-height: 30px;
  }

  header.mobile-nav-open ul li:nth-child(2),
  header ul li:nth-child(2) {
    top: calc(50px + 2rem + 1px);
  }

  header.mobile-nav-open.fixed-header ul li:nth-child(2),
  header.fixed-header ul li:nth-child(2) {
    top: calc(30px + 2rem);
  }

  header.mobile-nav-open ul li:nth-child(3),
  header ul li:nth-child(3) {
    top: calc(50px + 2rem + 1px + 61px);
  }

  header.mobile-nav-open.fixed-header ul li:nth-child(3),
  header.fixed-header ul li:nth-child(3) {
    top: calc(30px + 2rem + 61px);
  }

  header.mobile-nav-open ul li:nth-child(4),
  header ul li:nth-child(4) {
    top: calc(50px + 2rem + 1px + 61px + 61px);
  }

  header.mobile-nav-open.fixed-header ul li:nth-child(4),
  header.fixed-header ul li:nth-child(4) {
    top: calc(30px + 2rem + 61px + 61px);
  }

  header.mobile-nav-open ul li:nth-child(5),
  header ul li:nth-child(5) {
    top: calc(50px + 2rem + 1px + 61px + 61px + 61px);
  }

  header.mobile-nav-open.fixed-header ul li:nth-child(5),
  header.fixed-header ul li:nth-child(5) {
    top: calc(30px + 2rem + 61px + 61px + 61px);
  }

  header.mobile-nav-open ul li:nth-child(6),
  header ul li:nth-child(6) {
    top: calc(50px + 2rem + 1px + 61px + 61px + 61px + 61px);
  }

  header.mobile-nav-open.fixed-header ul li:nth-child(6),
  header.fixed-header ul li:nth-child(6) {
    top: calc(30px + 2rem + 61px + 61px + 61px + 61px);
  }

  /* ----- Nav: Page Overlay ----- */
  header.mobile-nav-open ul li:last-child::before {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: calc(50px + 2rem + 1px);
    left: 0;

    content: "";

    background-color: rgba(0, 0, 0, 50%);

    z-index: 1;
  }

  header.mobile-nav-open.fixed-header ul li:last-child::before {
    top: calc(30px + 2rem);
    height: calc(100vh - (30px + 2rem));
  }

  /* ----- Nav: Background Color ----- */
  header ul li:last-child::after {
    position: absolute;
    width: 0;
    height: 100vh;
    top: calc(50px + 2rem + 1px);
    right: 0;

    content: "";
    background-color: var(--color-dark);

    z-index: 2;

    transition: width 0.3s ease;
  }

  header.mobile-nav-open ul li:last-child::after {
    width: 200px;
  }

  header.mobile-nav-open.fixed-header ul li:last-child::after {
    top: calc(30px + 2rem);
  }
}
