:root {
  --color-dark: #13191b;
  --color-medium-dark: #E6E7E9;
  --color-medium: #fbfcfe;
  --color-light: #ffffff;
  --color-accent: #00adef;
  --color-alt-accent: #B68F66;
  --color-form-element: #E7EAEC;
  --color-success: #3DBC4C;
  --color-error: #D43531;

  --text-dark: #141a1c;
  --text-medium: #656f7c;
  --text-light: #ffffff;
  --text-accent: #00adef;
  --text-form-element: #b1c4e1;

  --header-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  --main-shadow: 6px 6px 18px 0px rgb(31 31 139 / 20%);
  --text-shadow: 0.08em 0.08em 0.08em rgb(31 31 139 / 20%);

  --main-gradient: linear-gradient(45deg, #00adef 0%, #0061a6 100%);
}

html {
  font-size: 75%;
}

body {
  margin: 0;
  background-color: var(--color-light);
}

main {
  padding-top: calc(50px + 2rem + 1px);
  transition: padding 0.3s ease;
}

main.fixed-header {
  padding-top: calc(30px + 2rem);
}

p {
  font: normal normal 300 2rem "Lato", sans-serif;
  line-height: 1.3em;
  color: var(--text-medium);
}

/* ===== Commons ===== */
.text-center {
  text-align: center;
}

.accent {
  color: var(--text-accent);
}

section h1,
section h2 {
  margin-bottom: 4rem;
  line-height: 1.2em;
}

.max-width-section {
  text-align: center;
  padding: 5rem 5rem 0 5rem;
}

.main-section *,
.max-width-section * {
  max-width: 800px;
  margin: 0 auto;
}

.main-section *:not(:first-child),
.max-width-section  *:not(:first-child) {
  margin-top: 4rem;
}

input,
textarea {
  padding: 1rem;
  outline: none;
  background-color: var(--color-form-element);
  border: none;
  border-radius: 5px;

  font: normal normal 300 1.5rem "Lato", sans-serif;
  color: var(--text-medium);
}

input::placeholder,
textarea::placeholder {
  color: var(--text-medium);
}

.alert {
  max-height: 0;
  padding: 0;
  transform: scale(0);
  overflow: hidden;
  border-radius: 5px;
  
  font: normal normal 300 1.5rem "Lato", sans-serif;
  text-align: center;

  transition: max-height 0.75s ease, padding 0.75s ease, transform 0.75s ease;
}

.alert.success {
  max-height: 80px;
  padding: 1rem;
  transform: scale(1);

  background-color: var(--color-success);
  color: var(--text-light);
}

.alert.error {
  max-height: 80px;
  padding: 1rem;
  transform: scale(1);

  background-color: var(--color-error);
  color: var(--text-light);
}

.text-page {
  width: 70%;
  margin: auto;
  padding-bottom: 6rem;

  line-height: 1.3em;
}

.text-page section:last-child {
  margin-bottom: 0;
}

.text-page p,
.text-page ul {
  margin: 1rem 0 1.5rem 0;

  font: normal normal 300 1.75rem "Lato", sans-serif;
  color: var(--text-medium);
  text-align: justify;
}

.text-page.text-center p {
  text-align: center;
}

.text-page a {
  color: var(--text-accent);
  word-wrap: break-word;
  text-decoration: none;

  transition: color 0.3s ease;
}

.text-page a:hover {
  color: var(--text-medium);
}

.btn-light {
  display: inline-block;
  -webkit-appearance: none;

  box-shadow: var(--main-shadow);
  -moz-box-shadow: var(--main-shadow);
  -webkit-box-shadow: var(--main-shadow);

  width: fit-content;
  background-color: var(--color-accent);
  padding: 1.25rem 2.5rem;
  border: none;
  border-radius: 3rem;

  font: normal normal 700 1.6rem "Poppins", sans-serif;
  color: var(--text-light);
  text-decoration: none;
  cursor: pointer;

  transition: color 0.3s ease, background-color 0.3s ease;
}

.btn-light:hover {
  color: var(--text-accent);
  background-color: var(--color-dark);
}

.btn-accent {
  display: inline-block;
  -webkit-appearance: none;

  box-shadow: var(--main-shadow);
  -moz-box-shadow: var(--main-shadow);
  -webkit-box-shadow: var(--main-shadow);

  width: fit-content;
  background-color: var(--color-alt-accent);
  padding: 1.25rem 2.5rem;
  border: none;
  border-radius: 3rem;

  font: normal normal 700 1.6rem "Poppins", sans-serif;
  color: var(--text-light);
  text-decoration: none;
  cursor: pointer;

  transition: color 0.3s ease, background-color 0.3s ease;
}

.btn-accent:hover {
  background-color: #E4D6C7;
  color: var(--color-alt-accent);
}

.btn-hero {
  font-size: 2.25rem;
  padding: 1.25rem 5rem;
  margin-top: 7rem !important;
}

/* ===== Headings ===== */
h2 {
  margin: 0;

  font: normal normal 700 3rem "Poppins", sans-serif;
  color: var(--text-dark);
}

h3 {
  margin: 0;

  font: normal normal 700 2rem "Poppins", sans-serif;
  color: var(--text-dark);
}

.text-page h1 {
  margin-top: 5rem;
  margin-bottom: 3rem;

  font: normal normal 700 3rem "Poppins", sans-serif;
  color: var(--text-dark);
}

.text-page h1::after {
  display: block;
  content: "";
  width: 50px;
  height: 10px;
  margin-top: 2.5rem;
  background-color: var(--color-accent);
}

.text-page.text-center h1::after {
  margin-left: auto;
  margin-right: auto;
}

.text-page h2 {
  margin: 0;

  font: normal normal 700 1.75rem "Poppins", sans-serif;
  color: var(--text-dark);
}

.text-page h3 {
  margin: 0;

  font: normal normal 500 1.75rem "Poppins", sans-serif;
  color: var(--text-dark);
}

.section-title {
  margin: 0 auto;

  font: italic normal 700 1.6rem "Lato", sans-serif;
  text-transform: lowercase;
  color: var(--text-accent);
}

/* ===== Section 1: Above the fold ===== */
.hero {
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  background: var(--color-dark);
  min-height: calc(100vh - (50px + 2rem + 1px));
  padding: 5rem;

  text-align: center;
}

.hero-image {
  position: absolute;
  display: block;

  max-width: initial;

  width: 100%;
  height: 100%;
  overflow: hidden;

  margin: 0 0 0 -5rem !important;
  opacity: 0.45;

  object-position: top;
  object-fit: cover;
  z-index: 1;
}

.hero::after {
  position: absolute;
  display: block;

  content: url("images/dotted_soundwave.svg");
  width: 85%;
  height: auto;

  left: 50%;
  transform: translateX(-50%);

  color: var(--color-light);
  opacity: 0.075;

  z-index: 2;
}

.hero *:not(.hero-image) {
  z-index: 3;
}

.hero h1 {
  font: normal normal 700 3.5rem "Poppins", sans-serif;
  color: var(--text-light);
  text-shadow: var(--text-shadow);
  line-height: 1.2em;
}

/* ===== Section 2: Unsere Services ===== */
#services-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 5rem;

  width: 75%;
  margin: 0 auto 10rem auto;
  padding-top: 5rem;

  text-align: center;
}

#services-section h2 {
  margin-bottom: 5rem;
}

#services-section * {
  grid-column-start: 1;
  grid-column-end: 4;
}

#services-section .first-sub-section {
  grid-column-start: 1;
  grid-column-end: 2;
}

#services-section .second-sub-section {
  grid-column-start: 2;
  grid-column-end: 3;
}

#services-section .third-sub-section {
  grid-column-start: 3;
  grid-column-end: 4;
}

.first-sub-section p,
.second-sub-section p,
.third-sub-section p {
  margin-bottom: 0;
}

.services-illustration {
  max-width: 100%;
  height: 9rem;

  margin-bottom: 3rem;
}

#services-section h3::before {
  display: block;

  content: "";
  width: 50px;
  height: 10px;

  margin: 0 auto 3rem auto;

  background-color: var(--color-accent);
}

/* ===== Section 3: Unsere Referenzen ===== */
#our-references-section {
  position: relative;
  text-align: center;

  min-height: 820px;
  box-sizing: border-box;

  margin: 0 auto 3.25rem auto;
  padding-top: 5rem;
}

#our-references-section *:not(.player *) {
  max-width: 550px;
  margin: 0 auto 4rem auto;
}

#our-references-section h2 {
  position: relative;
}

#our-references-section h2::before {
  position: absolute;
  width: 95px;
  height: 50px;

  top: 50%;
  left: 0;
  transform: translateY(-50%) scale(-1, -1);

  content: "";
  background: url("images/soundbars.svg") no-repeat;
}

#our-references-section h2::after {
  position: absolute;
  width: 95px;
  height: 50px;

  top: 50%;
  right: 0;
  transform: translateY(-50%);

  content: "";
  background: url("images/soundbars.svg") no-repeat;
}

/* --- Cover-Bilder --- */

.reference-cover-image {
  position: absolute;

  width: 280px;
  height: 280px;

  -webkit-filter: saturate(0%);
  filter: saturate(0%) brightness(1.5);
}

.reference-cover-image.small {
  width: 140px;
  height: 140px;
}

.reference-cover-image.pos-1 {
  top: 0;
  left: 0;

  opacity: 0.05;
}

.reference-cover-image.pos-2 {
  top: 0;
  left: 320px;

  opacity: 0.03;
}

.reference-cover-image.pos-3 {
  top: 320px;
  left: 0;

  opacity: 0.03;
}

.reference-cover-image.pos-4 {
  top: 320px;
  left: 180px;

  opacity: 0.03;
}

.reference-cover-image.pos-5 {
  top: 500px;
  left: 0;

  opacity: 0.05;
}

.reference-cover-image.pos-6 {
  top: 640px;
  left: 180px;

  opacity: 0.05;
}

.reference-cover-image.pos-7 {
  top: 0;
  right: 180px;

  opacity: 0.05;
}

.reference-cover-image.pos-8 {
  top: 0;
  right: 0;

  opacity: 0.05;
}

.reference-cover-image.pos-9 {
  top: 320px;
  right: 90px;

  opacity: 0.03;
}

.reference-cover-image.pos-10 {
  top: 500px;
  right: 180px;

  opacity: 0.03;
}

.reference-cover-image.pos-11 {
  top: 500px;
  right: 0;

  opacity: 0.05;
}

.reference-cover-image.pos-12 {
  top: 680px;
  right: 0;

  opacity: 0.05;
}

/* ===== Section 4: Testimonials ===== */
#testimonial-section {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 5rem;

  padding: 3rem;
  background: var(--main-gradient);
}

#testimonial-section::before {
  position: absolute;
  display: block;
  overflow: hidden;
  content: "";

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background: url("images/studio/gear_close_up.webp") center/cover;
 
  opacity: 0.25;

  z-index: 1;
}

#testimonial-section > section {
  max-width: 350px;
  padding: 2rem;

  background-color: var(--color-light);
  text-align: center;
  border-radius: 10px;
  box-shadow: var(--main-shadow);

  z-index: 3;
}

.testimonial-image {
  max-width: 15.75rem;
  border-radius: 50%;
}

.testimonial-role {
  margin-bottom: 0;

  font: italic normal 700 1.6rem "Lato", sans-serif;
  text-transform: lowercase;
  color: var(--text-accent);
}

#testimonial-section .first-testimonial {
  justify-self: right;
}

#testimonial-section .second-testimonial {
  justify-self: center;
}

#testimonial-section .third-testimonial {
  justify-self: left;
}

/* ===== Section 5: Über Uns ===== */
#about-us-section {
  position: relative;
}

#about-us-section h2 {
  margin-top: 0;
}

#about-us-section::after {
  display: block;
  content: url("images/soundbars_divider.svg");
  width: calc(100% + 10rem);
  height: auto;

  margin: 2rem 0 0 -5rem;
}

.headshot-image {
  max-width: 25rem;
  max-height: 25rem;
  border-radius: 50%;
}

#about-us-section .reference-logo {
  position: absolute;
  opacity: 0.5;
}

#about-us-section .reference-logo.left {
  left: 180px;
}

#about-us-section .reference-logo.right {
  right: 180px;
}

#about-us-section .reference-logo.top {
  top: 50px;
}

#about-us-section .reference-logo.bottom {
  top: 500px;
}

#about-us-section .reference-logo.left.bottom,
#about-us-section .reference-logo.right.top {
  transform: rotate(15deg);
}

#about-us-section .reference-logo.left.top,
#about-us-section .reference-logo.right.bottom {
  transform: rotate(-15deg);
}

/* ===== Section 6: Kontakt ===== */
#contact-section {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 3rem;

  margin: 0 auto 4rem auto;
  padding-top: 4rem;
}

#contact-section * {
  z-index: 3;
}

#contact-section::before {
  position: absolute;
  display: block;
  content: "";
  overflow: hidden;

  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% + 4rem);

  background: url("images/studio/contact_background.webp") center/cover;
  
  opacity: 0.1;
  z-index: 1;
}

#contact-section h2 {
  font-size: 2.8rem;
  text-align: center;
}

#contact-section form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}

#contact-section form *:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 2;
}

#contact-section form *:nth-child(2) {
  grid-column-start: 2;
  grid-column-end: 3;
}

#contact-section form *:nth-child(3),
#contact-section form *:nth-child(4),
#contact-section form *:nth-child(5) {
  grid-column-start: 1;
  grid-column-end: 3;
}

#contact-section form *:nth-child(5) {
  justify-self: center;
  padding: 1.25rem 3.5rem;
}

#contact-section .centered-column {
  padding: 2rem;
  max-width: 700px;
  margin: auto;

  background-color: var(--color-light);
  border-radius: 10px;
  box-shadow: var(--main-shadow);
}

#contact-section .contact-email {
  color: var(--text-accent);
  text-decoration: underline;
  transition: color 0.3s ease;
}

#contact-section .contact-email:hover {
  color: var(--text-dark);
}

/********************
* ===== Cover ===== *
********************/
@media (max-width: 1550px) {
  #our-references-section {
    overflow: hidden;
  }

  .reference-cover-image.pos-1,
  .reference-cover-image.pos-3,
  .reference-cover-image.pos-5 {
    left: calc((100vw - 1550px) / 2);
  }

  .reference-cover-image.pos-2 {
    left: calc(((100vw - 1550px) / 2) + 320px);
  }

  .reference-cover-image.pos-4,
  .reference-cover-image.pos-6 {
    left: calc(((100vw - 1550px) / 2) + 180px);
  }

  .reference-cover-image.pos-7,
  .reference-cover-image.pos-10,
  .reference-logo.right {
    right: calc(((100vw - 1550px) / 2) + 180px);
  }

  .reference-cover-image.pos-8,
  .reference-cover-image.pos-11,
  .reference-cover-image.pos-12 {
    right: calc((100vw - 1550px) / 2);
  }

  .reference-cover-image.pos-9 {
    right: calc(((100vw - 1550px) / 2) + 90px);
  }
}

/********************
* ===== Referenzen ===== *
********************/
@media (max-width: 1730px) {
  #about-us-section {
    overflow: hidden;
  }

  .reference-logo.left {
    left: calc(((100vw - 1730px) / 2) + 180px) !important;
  }

  .reference-logo.right {
    right: calc(((100vw - 1730px) / 2) + 180px) !important;
  }
}

/*********************
* ===== Tablet ===== *
*********************/
@media (max-width: 1024px) {
  /* ===== Commons ===== */
  .text-page {
    width: 86%;
  }

  /* ===== Section 1: Above the fold ===== */
  .hero::after {
    content: unset;
  }

  /* ===== Section 2: Unsere Services ===== */
  #services-section {
    width: 90%;
    column-gap: 2rem;
    grid-template-columns: 1fr;
  }

  #services-section * {
    grid-column-start: 1 !important;
    grid-column-end: 2 !important;
  }

  #services-section .first-sub-section {
    grid-row-start: 5;
    grid-row-end: 6;
  }
  
  #services-section .second-sub-section {
    grid-row-start: 3;
    grid-row-end: 4;
  }
  
  #services-section .third-sub-section {
    grid-row-start: 4;
    grid-row-end: 5;
  }

  #services-section .second-sub-section,
  #services-section .third-sub-section {
    margin-bottom: 3rem;
  }

  /* ===== Section 4: Testimonials ===== */
  #testimonial-section {
    column-gap: 2rem;
  }

  .testimonial-image {
    max-width: 12rem;
    max-height: 12rem;
  }

  /* ===== Section 6: Kontakt ===== */
  #contact-section {
    grid-template-columns: 1fr;
  }

  #contact-section h2 {
    margin-bottom: 3rem;
  }
}

/***************************
* ===== Small Tablet ===== *
***************************/
@media (max-width: 850px) {
  /* ===== Section 4: Testimonials ===== */
  #testimonial-section {
    grid-template-columns: 1fr;
  }

  #testimonial-section > section {
    max-width: initial;
    box-sizing: border-box;
    margin-bottom: 2rem;
  }

  #testimonial-section .first-testimonial {
    grid-row-start: 3;
    grid-row-end: 4;
  }

  #testimonial-section .second-testimonial {
    grid-row-start: 1;
    grid-row-end: 2;
  }

  #testimonial-section .third-testimonial {
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .testimonial-image {
    max-width: 10rem;
    max-height: 10rem;
  }

  /* ===== Section 6: Kontakt ===== */
  #contact-section .centered-column {
    width: 80%;
  }
}

/*********************
* ===== Mobile ===== *
*********************/
@media (max-width: 650px) {
  html {
    font-size: 56.25%;
  }

  /* ===== Commons ===== */
  .max-width-section {
    padding: 3rem;
  }

  .text-page {
    padding-bottom: 2.25rem;
  }

  .btn-dark:hover {
    color: var(--text-accent);
  }

  /* ===== Headings ===== */
  .text-page h1 {
    margin-top: 2.25rem;
  }

  /* ===== Section 1: Above the fold ===== */
  .hero h1 {
    font-size: 3rem;
  }

  .btn-hero {
    padding: 1.25rem 3.5rem;
    margin-top: 5rem !important;
  }

  /* ===== Section 2: Unsere Services ===== */
  #services-section {
    margin-bottom: 3rem;
  }

  /* ===== Section 3: Unsere Referenzen ===== */
  #our-references-section {
    padding: calc(3rem + 50px) 3rem 0 3rem;

    min-height: initial;
    overflow: initial;
  }

  #our-references-section h2::before {
    top: -75px;
    left: 25px;
    transform: scale(-1, -1);
  }

  #our-references-section h2::after {
    top: -75px;
    right: 25px;
    transform: unset;
  }

  .reference-cover-image {
    display: none;
  }

  /* ===== Section 4: Testimonials ===== */
  #testimonial-section {
    padding: 3rem 5rem;
  }

  /* ===== Section 5: Über Uns ===== */
  #about-us-section {
    padding: 3rem 3rem 0 3rem;
  }

  #about-us-section::after {
    width: calc(100% + 6rem);

    margin: 3rem 0 0 -3rem;
  }

  /* ===== Section 6: Kontakt ===== */  
  #contact-section {
    padding-top: 3rem;
  }

  #contact-section * {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  #contact-section form *:nth-child(1),
  #contact-section form *:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  #contact-section form *:nth-child(5) {
    justify-self: center;
  }
}
