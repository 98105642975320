.player {
  max-width: 500px;

  border-radius: 10px;
  box-shadow: var(--main-shadow);
}

.player-header {
  display: flex;
  position: relative;
  overflow: hidden;
  height: 10rem;

  border-radius: 10px 10px 0 0;
}

.player-header .background {
  position: absolute;
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  object-fit: cover;

  -webkit-filter: blur(10px) brightness(60%);
  filter: blur(10px) brightness(60%);

  border-radius: 10px 10px 0 0;
}

.player-header .front {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 6rem 1fr;
  column-gap: 2rem;

  padding: 2rem;
  box-sizing: border-box;

  border-radius: 10px 10px 0 0;
}

.player-header .front .avatar {
  width: 6rem;
  height: 6rem;

  border-radius: 50%;
}

.player-header .front .information {
  display: grid;
  grid-template-rows: 3rem 2.416666666666rem;
  row-gap: 0.583333333333rem;
}

.player-header .front .information .title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  font: normal normal 300 2rem "Lato", sans-serif;
  text-align: left;
  line-height: 3rem;
  color: white;
}

.player-header .front .information .tag {
  width: fit-content;

  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background-color: var(--color-accent);

  font: normal normal 700 1.25rem "Poppins", sans-serif;
  color: white;
}

.player-progress-bar-container {
  background-color: var(--color-form-element);
  cursor: pointer;
}

.player-progress-bar {
  width: 0;
  height: 0.5rem;
  background-color: var(--color-accent);

  transition: width 0.3s linear;
}

.player-controls {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 6rem;
  background-color: var(--color-medium);
}

.player-controls *:not(:last-child) {
  margin-right: 2rem;
}

.player-controls .play-pause-btn {
  width: 3.5rem;
  height: 3.5rem;

  color: var(--text-medium);

  transition: color 0.3s ease;
  cursor: pointer;
}

.player-controls .fwd-bwd-btn {
  width: 1.5rem;
  height: 1.5rem;

  color: var(--text-medium);

  transition: color 0.3s ease;
  cursor: pointer;
}

.player-controls .play-pause-btn:hover,
.player-controls .fwd-bwd-btn:hover {
  color: var(--text-accent);
}

.player-controls .fwd-bwd-btn.disabled {
  opacity: 0.15;
  cursor: initial;
}

.player-controls .fwd-bwd-btn.disabled:hover {
  color: var(--text-medium);
}

.player-playlist {
  border-radius: 0 0 10px 10px;
  background-color: var(--color-medium);
  padding: 0 1rem;
}

.player-playlist .playlist-item {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 2rem;

  padding: 1rem;
  border-top: 1px solid var(--color-medium-dark);
  cursor: pointer;

  font: normal normal 300 1.5rem "Lato", sans-serif;
  line-height: 1.3em;
  color: var(--text-medium);
  text-align: left;   
}

.player-playlist .playlist-item.active {
  color: var(--text-accent);
}

/*********************
* ===== Mobile ===== *
*********************/
@media (max-width: 650px) {
  .player {
    margin: auto;
  }

  .player-header .front .information .title {
    text-align: left;
  }
}
